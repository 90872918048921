import { DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BarChartDataItem } from '@app-model/dashboard';
import { v4 as uuidv4 } from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { Chart, registerables, LayoutPosition } from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, AfterViewInit {

  @Input() labels: string[];
  @Input() data: BarChartDataItem[];
  @Input() dataInfo: any[];
  @Input() typeChart: any = 'line';
  @Input() positionChart: LayoutPosition = "right";
  @Input() statType: string;

  private decimalPipe: DecimalPipe;
  height: number;
  uuid: string;

  constructor(public translate: TranslateService) {
    this.uuid = uuidv4();
  }

  ngOnInit(): void {
    this.decimalPipe = new DecimalPipe(this.translate.currentLang);

  }

  ngAfterViewInit(): void {
    if (this.data !== undefined && this.labels !== undefined) {
      this.handleInitChartjs()
    }
  }

  private handleInitChartjs(): void {

    const horizontal = this.data.length > 10;
    if (horizontal) {
      this.height = this.data.length * 32 + 48;
    }


    const customColors = [
      '#054659', '#F18700', '#2A77AD', '#F9B000', '#3BB93B', '#7460DD', '#0099C9',
      '#F0BE4B', '#61CE61', '#20829E', '#9487D3', '#FFAC41', '#50BEDF', '#B9DFB9',
      '#6952D9', '#0DA9D9', '#F29E2F', '#1CAE94', '#B47F03', '#3494D9', '#503FA6',
      '#73D973', '#D97B00', '#EBA70A', '#EBC70A'
    ];

    const title = (tooltipItems) => {
      const dataClients = this.data.map(item => item?.client);
      const dataGroups = this.data.map(item => item?.group);
      let title = tooltipItems[0].label;
      let subTitle = dataGroups[tooltipItems[0].dataIndex] !== undefined
        ? dataClients[tooltipItems[0].dataIndex] + ' | ' + dataGroups[tooltipItems[0].dataIndex]
        : dataClients[tooltipItems[0].dataIndex];

      return [title, subTitle]
    };

    const chart = new Chart(this.uuid, {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [
          {
            label: this.translate.instant('stats.' + (this.statType === 'PAGEVIEW' ? 'views' : 'clicks').toLowerCase()),
            data: this.data.map(item => item.value),
            backgroundColor: customColors,
            borderRadius: 4,
            barThickness: horizontal ? 24 : undefined
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: horizontal ? 'y' : 'x',

        plugins: {
          tooltip: {
            callbacks: {
              title: title,
              labelTextColor: function (context) {
                return '#FFFFFF';
              },
              label: function (context) {
                const total = context.dataset.data
                  .reduce((accumulator: number, currentValue: number) => accumulator + currentValue);
                const totalNumber = Array.isArray(total) ? total[0] : total;
                return ' ' + context.raw + ' ' + context.dataset.label + ' (' + `${(Math.round(context.parsed.y) / totalNumber * 100).toFixed(0)}%` + ')';
              },
            },
            bodyFont: {
              size: 12,
            },
            position: 'nearest',
            titleColor: '#FFFFFF',
            backgroundColor: '#262626',
            displayColors: true
          },
          legend: {
            display: false,
            position: 'right',
            labels: {
              padding: 20,
              font: {
                size: 12,
                weight: '400',
                lineHeight: 22,
                family: 'Roboto'
              },
              usePointStyle: true,
              boxHeight: 7,
              pointStyleWidth: 10,
            }
          },
          datalabels: {
            color: '#ffffff',
            anchor: horizontal ? 'center' : 'end',
            align: horizontal ? 'center' : 'bottom',
            clamp: true,
            clip: true,
            font: {
              weight: 'bold',
            },
            formatter: (value, context) => {
              if (value) {
                return this.decimalPipe.transform(value.toString());
              }
              return value;
            },

          },
        },
        scales: {
          x: {
            beginAtZero: true,
            display: horizontal ? undefined : true,
            grid: {
              display: horizontal,
            },
            border: {
              display: false,
            },
            ticks: {
              maxTicksLimit: horizontal ? 5 : undefined
            }
          },
          y: {
            beginAtZero: true,
            display: horizontal ? true : undefined,
            title: {
              display: horizontal ? undefined : true,
            },
            grid: {
              display: !horizontal,
            },
            border: {
              display: false,
            },
            ticks: {
              maxTicksLimit: horizontal ? undefined : 5,
            },
          },
        },
      },
    });

  }

}
